import React from 'react'
import Link from 'gatsby-link'
import {impresum} from '../strings'

var prefix = "";
function Languages(prefix_string_page){
    if(prefix_string_page === "bs"){
      prefix = "bs";
    }
    return prefix;
}

const FooterCopyRight = ({prefix_string_page}) => (
            <div className="columns footer-copy">
                <Link to="/">&copy; WELL BUSINESS PROMOTION</Link>
                <Link to={Languages(prefix_string_page) + "/impresum "}>{impresum}</Link>
            </div>
)

//CONTENT OF FOOTER COPYRIGHT//
export default FooterCopyRight
