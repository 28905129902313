import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import LayoutBs from '../../components/LayoutBs'
import get from 'lodash/get'
import {previous_page, next_page} from '../../components/strings'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import "animate.css/animate.min.css";
import {blog} from '../../components/strings'
import Helmet from 'react-helmet'
import AOS from 'aos';

 class BlogPageBs extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog/" : ("/blog/" + (currentPage - 1).toString())
    const nextPage = "/blog/" + (currentPage + 1).toString()
    const prefix_string = "bs";

    return (
      <LayoutBs>
        <Helmet
          title="WELL – BLOG | Čitaj o najnovijim dostignućima digitalnog marketinga"
          meta={[
            { name: 'description', content: 'U blogu WELL-a ćeš saznati više o trendovima modernih digitalno marketinških metoda kao i o najboljim online marketinškim instrumentima.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} >
        </Helmet>

          <div id="blog">
          <Header prefix_string_page={prefix_string}/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_blog" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
           {/* <h2 className="blog-soon">Naš blog će biti dostupan uskoro...</h2> */}
            <div className="container">        
       
                <h2 className="title">{blog}</h2>
                <div className="columns">
                    <div className="columns is-multiline">
                        {posts.map(({ node }) => {
                          return( 
                           
                          <div data-aos='fade-zoom-in' className="column is-12 single-blog">
                          
                          <Link to={node.fields.slug} className="blog-image"><img src={node.frontmatter.image} alt={node.frontmatter.meta} />
                          <div className="overlay"></div>
                          </Link>
                            <div className="blog-content">
                            <Link to={node.fields.slug}> <h1 className="title">{node.frontmatter.title}</h1></Link>
                                <p className="desc">{node.frontmatter.description}</p>
                          </div>  
                          </div>
                        
                        )  })}   
                        
                          
                   
                    </div>

                    </div>

                    <ul className="pagination">
                      { !isFirst && <Link to={"/bs" + prevPage} rel="prev">← {previous_page}</Link> }
                      { Array.from({ length: numPages }, (_, i) => (
                          <li key={`pagination-number${i + 1}`} >
                            <Link to={"/bs" + `/portfolio/${i === 0 ? '' : i + 1}`} style={{
                                color: i + 1 === currentPage ? '#ffffff' : '', 
                                background: i + 1 === currentPage ? '#D4AC69' : ''
                              }} >
                              {i + 1}
                            </Link>
                          </li>
                        ))
                      }
                      { !isLast && <Link to={"/bs" + nextPage} rel="next">{next_page} &nbsp;→</Link> }
                  </ul>

                </div>
</section>
        </div>
        

        </div>

          <Footer prefix_string_page={prefix_string}/>
      </LayoutBs>
    )
  }
}

BlogPageBs.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPageBs

export const blogPageBsQuery = graphql`

  query blogPageBsQuery($skip: Int = 0, $limit: Int = 6) {
    allMarkdownRemark(sort: { fields: [frontmatter___sorting_date], order: ASC },
        filter: { frontmatter: { templateKey: { eq: "bs_blog" } } },
         limit: $limit, skip: $skip) {
        edges {
          node {
            excerpt
            id
            fields {
              slug
            }
            frontmatter {
                title
                client
                category
                date
                sorting_date
                website
                website_url
                description
                meta
                image
            }
          }
        }
      }
  }
`
