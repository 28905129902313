import React from 'react'
import FooterTitle from './title'
import FooterCopyRight from './copyright'
import Link from 'gatsby-link'
import {contact} from '../strings'
import 'aos/dist/aos.css';

const Footer = ({prefix_string_page}) => (
    <section id="s_footer" className="section default">
        <div className="overlay_image"></div>
            <div className="container">
                <div data-aos='fade-zoom-in' className="title-box">
                    <div className="first">
                        <h2 className="title"><span className="section-number"></span>{contact}</h2>
                    </div>
                </div>
                <FooterTitle prefix_string_page={prefix_string_page}/>
                <FooterCopyRight prefix_string_page={prefix_string_page}/>
            </div>
    <Link to="/" className="to_top"></Link>
  
    </section>
)

//TITLE OF FOOTER//
export default Footer
